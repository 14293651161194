import { useCallback } from 'react'
import { I18n } from '@lingui/core'
import { msg } from '@lingui/core/macro'
import { useLingui } from '@lingui/react/macro'
import { ErrorBoundary } from 'react-error-boundary'
import { Slide, useBrazeHeroBanner } from './use-braze-hero-banner'
import { getBrandConfig } from '@/config/config'
import { logCardClick, logContentCardImpressions } from '@braze/web-sdk'
import {
  SimpleHeroBanner,
  SimpleHeroBannerSkeleton,
} from '@vgw/multibrand-fe-simple-hero-banner'
import {
  CarouselHeroBanner,
  CarouselHeroBannerSkeleton,
} from '@vgw/multibrand-fe-carousel-hero-banner'
import {
  OverlayedHeroBanner,
  OverlayedHeroBannerSkeleton,
} from '@vgw/multibrand-fe-overlayed-hero-banner'

const getDefaultCards = (i18n: I18n) => {
  return [
    {
      src: 'default-hero-banner/eagle-vanilla-960w.webp',
      title: i18n._(msg`Welcome`),
      srcSet:
        'default-hero-banner/eagle-vanilla-960w.webp 960w, default-hero-banner/eagle-vanilla-1440w.webp 1440w,',
      thumbnailSrc: 'default-hero-banner/eagle-vanilla-thumb.webp',
    },
    {
      src: 'default-hero-banner/bear-vanilla-960w.webp',
      title: i18n._(msg`Welcome`),
      srcSet:
        'default-hero-banner/bear-vanilla-960w.webp 960w, default-hero-banner/bear-vanilla-1440w.webp 1440w,',
      thumbnailSrc: 'default-hero-banner/bear-vanilla-thumb.webp',
    },
    {
      src: 'default-hero-banner/casino-vanilla-960w.webp',
      title: i18n._(msg`Welcome`),
      srcSet:
        'default-hero-banner/casino-vanilla-960w.webp 960w, default-hero-banner/casino-vanilla-1440w.webp 1440w,',
      thumbnailSrc: 'default-hero-banner/casino-vanilla-thumb.webp',
    },
    {
      src: 'default-hero-banner/chip-vanilla-960w.webp',
      title: i18n._(msg`Welcome`),
      srcSet:
        'default-hero-banner/chip-vanilla-960w.webp 960w, default-hero-banner/chip-vanilla-1440w.webp 1440w,',
      thumbnailSrc: 'default-hero-banner/chip-vanilla-thumb.webp',
    },
  ]
}

const convertSlidesToCards = (slides: Slide[]) => {
  return slides.map((slide) => {
    const mobileImageUrl =
      slide.extras.mobile_image_url || slide.extras.desktop_image_url

    return {
      src: mobileImageUrl,
      title: slide.card.linkText || '', // TODO: to be replaced with slide.card.title
      url: slide.card.url,
      srcSet: `
          ${mobileImageUrl} 480w,
          ${slide.extras.desktop_image_url} 1024w,
        `,
      onCardClick: () => {
        logCardClick(slide.card, true)
      },
      thumbnailSrc:
        slide.extras.thumbnail_image_url || slide.extras.desktop_image_url,
    }
  })
}

const DefaultHeroBanner = () => {
  const { i18n } = useLingui()
  const cards = getDefaultCards(i18n)

  return <SimpleHeroBanner card={cards[0]} />
}

const LobbyHeroBannerContent = () => {
  const { i18n } = useLingui()
  const { isLoading, slides } = useBrazeHeroBanner()
  const brandConfig = getBrandConfig()
  const cards =
    slides.length === 0 ? getDefaultCards(i18n) : convertSlidesToCards(slides)

  const onCardEntersViewport = useCallback(
    (index: number) => {
      const slide = slides.at(index)
      if (!slide) return
      logContentCardImpressions([slide.card])
    },
    [slides],
  )

  if (brandConfig.heroBanner.type === 'simple') {
    if (isLoading) {
      return <SimpleHeroBannerSkeleton />
    }

    return (
      <SimpleHeroBanner
        card={cards[0]}
        onCardEntersViewport={onCardEntersViewport}
      />
    )
  }

  if (brandConfig.heroBanner.type === 'carouselOverlayed') {
    if (isLoading) {
      return <OverlayedHeroBannerSkeleton />
    }

    return (
      <OverlayedHeroBanner
        cards={cards}
        onCardEntersViewport={onCardEntersViewport}
      />
    )
  }

  const variant =
    brandConfig.heroBanner.type === 'carouselWithLeadingCard'
      ? 'leadingCard'
      : 'basic'

  return (
    <div className="pt-4">
      {isLoading ? (
        <CarouselHeroBannerSkeleton variant={variant} />
      ) : (
        <CarouselHeroBanner cards={cards} variant={variant} />
      )}
    </div>
  )
}

export const LobbyHeroBanner = () => {
  return (
    <ErrorBoundary fallback={<DefaultHeroBanner />}>
      <LobbyHeroBannerContent />
    </ErrorBoundary>
  )
}
