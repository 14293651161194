import {
  PeriodicBonusItem,
  PeriodicBonusItemProps,
} from '@/features/periodic-bonus/periodic-bonus-item/periodic-bonus-item'
import {
  Carousel,
  useCarousel,
  WheelGesturesPlugin,
  usePrevNextButtons,
} from '@vgw/multibrand-fe-carousel'
import { cn } from '@vgw/tailwind-merger'

type PeriodicBonusesListProps = {
  periodicBonuses: PeriodicBonusItemProps[]
  className?: string
  controlsClassName?: string
}

export const PeriodicBonusesList = ({
  periodicBonuses,
  className,
  controlsClassName,
}: PeriodicBonusesListProps) => {
  const [carouselRef, carouselApi] = useCarousel(
    { loop: false, align: 'start', dragFree: true },
    [WheelGesturesPlugin()],
  )

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(carouselApi)

  return (
    <div className={cn('flex w-full flex-col', className)}>
      <div className={cn('flex justify-end gap-2', controlsClassName)}>
        <Carousel.Arrow
          arrow="left"
          size="sm"
          onClick={onPrevButtonClick}
          disabled={prevBtnDisabled}
        />
        <Carousel.Arrow
          arrow="right"
          size="sm"
          onClick={onNextButtonClick}
          disabled={nextBtnDisabled}
        />
      </div>
      <Carousel>
        <Carousel.Container ref={carouselRef}>
          <Carousel.CardContainer className="gap-3">
            {periodicBonuses.map((periodicBonus, index) => (
              <PeriodicBonusItem key={index} {...periodicBonus} />
            ))}
          </Carousel.CardContainer>
        </Carousel.Container>
      </Carousel>
    </div>
  )
}
