import { useQuery } from '@tanstack/react-query'
import { getBrandConfig } from '@/config/config'
import {
  GameCardConfigResponse,
  GameCardResponse,
  getGameCardViewOptions,
} from '@/apis/gap-gallery-view'

import { useSelectedCoinType } from '@/use-selected-coin-type'

export type GameConfig = GameCardConfigResponse
export type GameCardData = GameCardResponse

export function useGameInfoQueryOptions(gameId: string) {
  const config = getBrandConfig()
  const selectedCoinType = useSelectedCoinType()

  return getGameCardViewOptions({
    // @ts-expect-error temporary fix for lobby, 'x-jwt-claim-subject' header will be removed from BE
    // TODO - update open api when related BE task is ready
    headers: {
      // set by interceptor globally
      Authorization: '',
    },
    query: {
      coinType: selectedCoinType,
    },
    path: {
      brandCode: config.code,
      gameId,
    },
  })
}

function useGameInfoQuery(gameId: string) {
  const options = useGameInfoQueryOptions(gameId)

  const { data, isLoading, error } = useQuery(options)

  return {
    isLoading,
    data,
    error,
  }
}

export { useGameInfoQuery }
