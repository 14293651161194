import { cn } from '@vgw/tailwind-merger'
import { Carousel } from '@vgw/multibrand-fe-carousel'
import { LayoutProps } from '@/features/layout/layout-factory'
import { LayoutItemCard } from '@/features/layout/components/layout-item-card'

export const LayoutRound = ({ games }: LayoutProps) => {
  return games.map((game, index) => (
    <Carousel.Card
      key={index}
      index={index}
      className={cn([
        'mr-2',
        'h-[116px]',
        'sm:mr-4',
        'md:h-[174px]',
        'lg:mr-6',
        'xl:h-[218px]',
      ])}
    >
      <LayoutItemCard
        name={game.name}
        imageUrl={game.thumbnails.image1x1}
        gameId={game.gameId}
        launchUrl={game.launchUrl}
        variant="round"
        jackpots={game.jackpots}
      />
    </Carousel.Card>
  ))
}
