import { NotVerifiedInGrace } from '@/features/periodic-bonus/grace-period/not-verified-in-grace'
import { PendingVerificationInGrace } from '@/features/periodic-bonus/grace-period/pending-verification-in-grace'
import { PendingVerificationAndLocked } from '@/features/periodic-bonus/grace-period/pending-verification-and-locked'
import { NotVerifiedAndLocked } from '@/features/periodic-bonus/grace-period/not-verified-and-locked'

interface GracePeriodProps {
  status:
    | 'NotVerifiedInGrace'
    | 'PendingVerificationInGrace'
    | 'NotVerifiedAndLocked'
    | 'PendingVerificationAndLocked'
  remainingDays: number
  onVerifyNow: () => void
}

export const GracePeriod = ({
  status,
  remainingDays,
  onVerifyNow,
}: GracePeriodProps) => {
  switch (status) {
    case 'NotVerifiedInGrace':
      return (
        <NotVerifiedInGrace
          remainingDays={remainingDays}
          onClick={onVerifyNow}
        />
      )
    case 'PendingVerificationInGrace':
      return <PendingVerificationInGrace remainingDays={remainingDays} />
    case 'NotVerifiedAndLocked':
      return <NotVerifiedAndLocked onClick={onVerifyNow} />
    case 'PendingVerificationAndLocked':
      return <PendingVerificationAndLocked />
    default:
      return null
  }
}
