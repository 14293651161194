import React, { forwardRef } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'

const gameTagVariantsContainer = cva(
  cn([
    'relative isolate w-fit bg-transparent',
    'before:content-[""]',
    'before:absolute',
    'before:h-full',
    'before:w-[12px]',
    'before:top-0',
    'before:right-[-15px]',
    'before:z-10',
    'before:skew-x-[-25deg]',

    'after:content-[""]',
    'after:absolute',
    'after:skew-x-[-25deg]',
    'after:rounded-br-sm',
    'after:rounded-tr-[3.5px]',
    'after:w-[4px]',
    'after:h-full',
    'after:top-0',
    'after:right-[-18px]',
    'after:z-0',
    'after:shadow-tag',
  ]),
  {
    variants: {
      variant: {
        NEW: 'before:bg-game-card-badge-surface-op-1 after:bg-game-card-badge-surface-op-1',
        HOT: 'before:bg-game-card-badge-surface-op-2 after:bg-game-card-badge-surface-op-2',
        EXCLUSIVE:
          'before:bg-game-card-badge-surface-op-3 after:bg-game-card-badge-surface-op-3',
        'SNEAK-PEEK':
          'before:bg-game-card-badge-surface-op-4 after:bg-game-card-badge-surface-op-4',
        'COMING-SOON':
          'before:bg-game-card-badge-surface-op-5 after:bg-game-card-badge-surface-op-5',
      },
    },
    defaultVariants: {
      variant: 'NEW',
    },
  },
)

const gameTagVariantsContent = cva(
  'shadow-tag w-[calc(100%+9px)] rounded-l-sm py-0.5 pl-2.5 text-sm leading-5',
  {
    variants: {
      variant: {
        NEW: 'text-game-card-badge-content-op-1 bg-game-card-badge-surface-op-1',
        HOT: 'text-game-card-badge-content-op-2 bg-game-card-badge-surface-op-2',
        EXCLUSIVE:
          'text-game-card-badge-content-op-3 bg-game-card-badge-surface-op-3',
        'SNEAK-PEEK':
          'text-game-card-badge-content-op-4 bg-game-card-badge-surface-op-4',
        'COMING-SOON':
          'text-game-card-badge-content-op-5 bg-game-card-badge-surface-op-5',
      },
    },
    defaultVariants: {
      variant: 'NEW',
    },
  },
)

export interface GameTagProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof gameTagVariantsContainer> {
  text: string
}

export const GameTag = forwardRef<HTMLDivElement, GameTagProps>(
  ({ text, variant, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(gameTagVariantsContainer({ variant, className }))}
        {...props}
      >
        <div className={gameTagVariantsContent({ variant })}>
          <span className="relative z-20 uppercase">{text}</span>
        </div>
      </div>
    )
  },
)

GameTag.displayName = 'GameTag'
