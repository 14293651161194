import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getBrandConfig } from '@/config/config'
import {
  addGameToFavorites,
  deleteGameFromFavorites1,
} from '@/apis/gap-gallery-user-preferences'
import { useGameInfoQueryOptions } from '@/features/game-api/use-game-info-query'

interface MutationInput {
  gameId: string
  isFavorite: boolean
}

function useToggleGameFavoriteMutation(gameId: string) {
  const config = getBrandConfig()
  const gameInfoQueryKey = useGameInfoQueryOptions(gameId).queryKey
  const queryClient = useQueryClient()

  const { error, data, isPending, mutate } = useMutation({
    mutationFn: async ({ gameId, isFavorite }: MutationInput) => {
      const action = isFavorite ? deleteGameFromFavorites1 : addGameToFavorites

      const { data } = await action({
        headers: {
          Authorization: null,
        },
        path: {
          brandCode: config.code,
          gameId,
        },
        query: {},
        throwOnError: true,
      })

      return data
    },

    onSettled: async (_data, _error) => {
      return await queryClient.invalidateQueries({
        queryKey: gameInfoQueryKey,
      })
    },
  })

  return {
    toggleFavorite: mutate,
    isPending,
    error,
    data,
  }
}

export { useToggleGameFavoriteMutation }
