import { cn } from '@vgw/tailwind-merger'
import { PropsWithChildren } from 'react'

export type ContentProps = {
  mainClassName?: string
  contentClassName?: string
}

export const Content = (props: PropsWithChildren<ContentProps>) => {
  const { mainClassName, contentClassName } = props || {}

  return (
    <main
      className={cn(
        'flex w-full flex-1 flex-col items-center bg-surface-secondary',
        mainClassName,
      )}
    >
      <div
        className={cn(
          'flex w-full max-w-screen-2xl items-center',
          contentClassName,
        )}
      >
        {props.children}
      </div>
    </main>
  )
}
