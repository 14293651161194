import { Drawer, DrawerContent, DrawerTrigger } from '@vgw/multibrand-fe-drawer'
import {
  CoinTypeProps,
  CoinTypeToggleWrapper,
} from './coin-type-toggle-wrapper'
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { UserSidebar } from './user-sidebar/user-sidebar'
import { useStoreLaunch } from '@/features/store/use-store-launch'

export interface UserHeaderProps {
  logout: () => Promise<void>
  username: string | undefined
  userEmail: string | undefined
  coinTypeInfo: CoinTypeProps
  withCompactStoreIcon?: boolean
}

export const UserHeader = ({
  logout,
  username,
  userEmail,
  coinTypeInfo,
  withCompactStoreIcon,
}: UserHeaderProps) => {
  const { amounts } = coinTypeInfo
  const { open: openStoreDialog } = useStoreLaunch()

  return (
    <>
      <div className="flex w-full gap-3 md:w-auto">
        <div className="hidden gap-3 lg:flex">
          <div className="h-[36px] w-[36px]" />
          <div className="h-[36px] w-[36px]" />
        </div>

        <div className="relative flex w-full">
          <CoinTypeToggleWrapper {...coinTypeInfo} />
          {withCompactStoreIcon && (
            <IconButton
              className="absolute right-[2px] top-[2px] lg:hidden"
              icon="shopping-bag"
              size="sm"
              variant="primary"
              data-testid="mobile-store-button"
              onClick={openStoreDialog}
            />
          )}
        </div>
        <div className="hidden gap-3 lg:flex">
          <IconButton
            icon="shopping-bag"
            size="md"
            variant="primary"
            data-testid="store-button"
            onClick={openStoreDialog}
          />
          <IconButton
            icon="redeem"
            size="md"
            variant="secondary"
            data-testid="redeem-button"
          />
        </div>
      </div>
      <Drawer>
        <DrawerTrigger data-testid="drawer-button">
          {/* TODO: replace with user profile image when API ready */}
          <IconButton
            icon="person"
            size="md"
            variant="primary"
            className="hidden lg:block"
            asChild
          >
            <div />
          </IconButton>
          <IconButton
            icon="person"
            size="sm"
            variant="primary"
            className="lg:hidden"
            asChild
          >
            <div />
          </IconButton>
        </DrawerTrigger>
        <DrawerContent position="right" className="bg-surface-neutral p-0">
          <UserSidebar
            logout={logout}
            username={username}
            userEmail={userEmail}
            coinAmounts={amounts}
          />
        </DrawerContent>
      </Drawer>
    </>
  )
}
