import {
  Select,
  Trigger,
  Icon,
  Content,
  Item,
  Viewport,
} from '@radix-ui/react-select'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import { cn } from '@vgw/tailwind-merger'
import { useContext, useState } from 'react'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import { MonthCaptionProps, useDayPicker } from 'react-day-picker'
import { CalendarContext } from '../calendar.context'

const formatter = new Intl.DateTimeFormat('en', { month: 'long' })
const getYears = (initialYear: number, yearsToRender: number) =>
  Array.from(Array(yearsToRender).keys()).map((_, index) => initialYear + index)

interface YearSelectButtonProps {
  calendarMonth: MonthCaptionProps['calendarMonth']
}

const YearSelectButton = ({ calendarMonth }: YearSelectButtonProps) => {
  const {
    inactiveYears,
    isDefaultCaptionOpen,
    setIsDefaultCaptionOpen,
    initialYear,
    yearsToRender,
  } = useContext(CalendarContext)

  const [isOpen, setIsOpen] = useState(isDefaultCaptionOpen)

  const { goToMonth } = useDayPicker()

  const currentMonth = calendarMonth.date
  const years = getYears(initialYear, yearsToRender)

  return (
    <Select
      onValueChange={(value) => {
        if (value) {
          goToMonth(new Date(parseInt(value), currentMonth.getMonth(), 1))
        }
      }}
      onOpenChange={() => {
        setIsOpen((value) => !value)
        if (setIsDefaultCaptionOpen) {
          setIsDefaultCaptionOpen(false)
        }
      }}
      value={currentMonth.getFullYear().toString()}
      open={isOpen}
    >
      <Trigger
        className={cn(
          'font-roboto text-content-base text-base font-normal leading-6',
          'inline-flex items-center justify-center gap-2',
          'px-2 py-1',
          'bg-form-input-surface-secondary rounded text-center',
          'hover:bg-button-surface-ghost-hover active:bg-button-surface-ghost-active',
          'outline-none',
        )}
      >
        {formatter.format(
          new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1),
        ) +
          ' ' +
          currentMonth.getFullYear()}
        <Icon>
          {isOpen ? (
            <CustomIcon name="triangle-up" />
          ) : (
            <CustomIcon name="triangle-down" />
          )}
        </Icon>
      </Trigger>
      <Content
        style={{
          width: '',
        }}
        className="mt-3 rounded-none p-0"
        position="popper"
      >
        <Viewport className="max-h-[240px] w-[248px] overflow-y-auto border-none">
          <ScrollArea.Root className="h-[244px] overflow-hidden">
            <ScrollArea.Viewport className="h-full">
              <div
                className={cn(
                  'bg-form-input-surface flex w-[240px] flex-row flex-wrap justify-between',
                )}
              >
                {years.map((year) => (
                  <Item
                    key={year}
                    className={cn(
                      {
                        'odd:bg-brand-secondary even:bg-brand-secondary':
                          year === currentMonth.getFullYear(),
                        'odd:bg-form-input-surface even:bg-form-input-surface':
                          year !== currentMonth.getFullYear(),
                        'text-content-muted pointer-events-none line-through':
                          inactiveYears.has(year),
                      },
                      'flex items-center justify-center',
                      'rounded',
                      'p-1',
                      'text-content-muted hover:text-content-inverse text-base font-light leading-6',
                      'outline-none',
                      'cursor-pointer',
                      'hover:bg-brand-secondary-shade',
                    )}
                    value={year.toString()}
                  >
                    {year}
                  </Item>
                ))}
              </div>
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar
              className={cn(
                'transition-colors duration-[160ms]',
                'flex touch-none select-none',
                'border-1 border-overlay-base border-solid',
                'bg-black-alpha-50 ease-out',
                'hover:bg-black-alpha-50',
                'data-[orientation=horizontal]:h-1.5',
                'data-[orientation=vertical]:w-1.5',
                'data-[orientation=horizontal]:flex-col',
              )}
              orientation="vertical"
            >
              <ScrollArea.Thumb
                className={cn(
                  'bg-overlay-base relative w-1.5 flex-1 rounded',
                  'before:absolute before:left-1/2 before:top-1/2 before:h-full',
                  'before:min-h-[44px] before:w-full before:min-w-[44px]',
                  'before:-translate-x-1/2 before:-translate-y-1/2',
                  'before:content-[""]',
                )}
              />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
        </Viewport>
      </Content>
    </Select>
  )
}

export default YearSelectButton
