import { useMemo } from 'react'
import { useLingui } from '@lingui/react/macro'
import {
  SimpleHeroBannerBase,
  SimpleHeroBannerBaseCard,
} from './simple-hero-banner-base'

interface SimpleHeroBannerCard {
  src: string
  title: string
  url?: string
  sizes?: string
  srcSet?: string
  onCardClick?: () => void
}

interface SimpleHeroBannerProps {
  card: SimpleHeroBannerCard
  onCardEntersViewport?: (cardIndex: number) => void
}

export const SimpleHeroBanner = ({
  card,
  onCardEntersViewport,
}: SimpleHeroBannerProps) => {
  const { t } = useLingui()

  const slide: SimpleHeroBannerBaseCard = useMemo(() => {
    return {
      MainElement: ({ className }) => {
        const img = (
          <img
            alt={card.url ? t`Navigate to ${card.title}` : card.title}
            src={card.src}
            sizes={card.sizes}
            srcSet={card.srcSet}
            className={className}
          />
        )

        return card.url ? (
          <a
            href={card.url}
            onClick={() => card.onCardClick?.()}
            className="w-full"
          >
            {img}
          </a>
        ) : (
          img
        )
      },
    }
  }, [card, t])

  return (
    <SimpleHeroBannerBase
      card={slide}
      onCardEntersViewport={onCardEntersViewport}
    />
  )
}
