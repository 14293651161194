import { cn } from '@vgw/tailwind-merger'
import { isSameDay } from 'date-fns'
import { useEffect, useRef } from 'react'
import { DayButtonProps as DayPickerDayButtonProps } from 'react-day-picker'

interface DayButtonProps extends DayPickerDayButtonProps {
  official?: Date | Date[]
}

const DayButton = ({ official, day, modifiers, ...props }: DayButtonProps) => {
  const date = day.date

  const ref = useRef<HTMLButtonElement | null>(null)
  const isFocused = modifiers.focused

  const isDayOfficial = (day: Date) => {
    if (Array.isArray(official)) {
      return official.some((date) => isSameDay(date, day))
    }

    if (official) {
      return isSameDay(official, day)
    }
  }

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus()
    }
  }, [isFocused])

  return (
    <button ref={ref} {...props} data-focused={isFocused}>
      <span
        className={cn([
          isDayOfficial(date) && 'text-gold-coins-surface-active',
        ])}
      >
        {date.getDate()}
      </span>
    </button>
  )
}

export default DayButton
