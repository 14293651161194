import { PeriodicBonusesList } from '@/features/periodic-bonus/periodic-bonuses-list/periodic-bonuses-list'
import { preparePeriodicBonuses } from '@/features/periodic-bonus/periodic-bonuses-list/periodic-bonuses-list-mocks'
import { Trans } from '@lingui/react/macro'
import { Chip } from '@vgw/multibrand-fe-chip'
import special_offer from '../assets/special_offer.png'
import { t } from '@lingui/core/macro'
import { PlayForFreeSkeleton } from '@/features/store/play-for-free/play-for-free-skeleton'
import { GracePeriod } from '@/features/periodic-bonus/grace-period/grace-period'

type PlayForFreeProps = {
  isLoading?: boolean
}

export const PlayForFree = ({ isLoading }: PlayForFreeProps) => {
  const currentStreak = t`7 Days Streak`

  return (
    <>
      <div data-testid="play-for-free-wrapper">
        {isLoading ? (
          <PlayForFreeSkeleton />
        ) : (
          <div className="relative rounded-lg bg-surface-neutral p-3">
            <div className="flex items-center justify-between lg:justify-normal">
              <div className="flex">
                <p className="mr-1.5 font-display text-lg font-bold leading-7 text-content-base">
                  <Trans>Daily Bonus</Trans>
                </p>
                <img
                  width={28}
                  height={28}
                  src={special_offer}
                  className="[filter:drop-shadow(0px_4px_4px_rgba(0,0,0,0.25))] lg:mr-2"
                  alt=""
                />
              </div>
              <div>
                <Chip>{currentStreak}</Chip>
              </div>
            </div>
            <div className="my-4 flex gap-3">
              <PeriodicBonusesList
                periodicBonuses={preparePeriodicBonuses()}
                controlsClassName="absolute right-3 top-3 hidden gap-2 pb-5 lg:flex"
              />
            </div>

            <GracePeriod
              remainingDays={7}
              /* eslint-disable-next-line lingui/no-unlocalized-strings */
              status="NotVerifiedInGrace"
              onVerifyNow={() => {}}
            />
          </div>
        )}
      </div>
    </>
  )
}
