import { Button } from '@vgw/multibrand-fe-button'
import { Trans } from '@lingui/react/macro'

interface GracePeriodNotRequiredProps {
  remainingDays: number
  onClick: () => void
}

export const NotVerifiedInGrace = ({
  remainingDays,
  onClick,
}: GracePeriodNotRequiredProps) => {
  return (
    <div className="flex flex-col gap-3 leading-6 text-content-base">
      <div>
        <p className="font-bold">
          <Trans>{remainingDays} days of Daily Bonus remaining!</Trans>
        </p>
        <p>
          <Trans>
            Verify your identity to continue collecting the bonuses after that.
          </Trans>
        </p>
      </div>
      <Button
        onClick={onClick}
        variant="primary"
        size="md"
        className="w-[120px] font-normal"
      >
        <Trans>Verify Now</Trans>
      </Button>
    </div>
  )
}
