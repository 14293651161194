import { Button } from '@vgw/multibrand-fe-button'
import { Trans } from '@lingui/react/macro'

interface GracePeriodRequiredProps {
  onClick: () => void
}

export const NotVerifiedAndLocked = ({ onClick }: GracePeriodRequiredProps) => {
  return (
    <div className="flex flex-col gap-3">
      <p className="font-body font-bold leading-6 text-content-base">
        <Trans>
          Verify your identity to collect the Daily Bonus and maintain your
          streak!
        </Trans>
      </p>
      <Button
        onClick={onClick}
        variant="primary"
        size="md"
        className="w-[120px] font-normal"
      >
        <Trans>Verify Now</Trans>
      </Button>
    </div>
  )
}
