import { FOOTER_LOGOS, SOCIAL_MEDIA_LOGOS } from '@/features/footer/config'

import { Disclaimer } from '@/features/footer/disclaimer'
import { LicenseDetails } from '@/features/footer/license-details'
import { CustomIcon, IconNames, IconSizes } from '@vgw/multibrand-fe-icon'
import { Link } from 'react-router-dom'
import { FooterNavigation } from './footer-navigation/footer-navigation'
import { useToggle } from '@/lib/use-toggle'
import { CookieConsentBanner } from './cookie-consent-banner/cookie-consent-banner'
import { Trans } from '@lingui/react/macro'

interface FooterNavIcon {
  name: IconNames
  url: string
  size?: string
  className?: string
}

interface FooterNavProps {
  rsgIcons: readonly FooterNavIcon[]
  socialMediaIcons: readonly FooterNavIcon[]
}

export interface FooterProps {
  isSweepsCoinsEnabled: boolean
}

const Footer = ({ isSweepsCoinsEnabled }: FooterProps) => {
  const cookiesBannerState = useToggle()

  return (
    <footer className="flex w-full max-w-screen-2xl items-center">
      <div className="flex w-full flex-col gap-6 px-2 py-10 text-xs text-content-base sm:px-4 md:px-6">
        <FooterNavigation onCookiesConsentOpen={cookiesBannerState.on} />
        <NavIcons
          rsgIcons={FOOTER_LOGOS}
          socialMediaIcons={SOCIAL_MEDIA_LOGOS}
        />
        <Disclaimer userHasSweepsCoinsFeature={isSweepsCoinsEnabled} />
        <LicenseDetails />
      </div>
      {cookiesBannerState.state && (
        <CookieConsentBanner onCookiesBannerHide={cookiesBannerState.off} />
      )}
    </footer>
  )
}

const NavIcons = ({ rsgIcons, socialMediaIcons }: FooterNavProps) => {
  return (
    <div className="flex max-w-full max-w-screen-lg flex-col items-center justify-start gap-5 md:flex-row lg:m-auto lg:gap-16">
      <div className="flex items-center gap-4">
        {createIconsGroup(rsgIcons)}
      </div>
      <Link
        key="rsg-link"
        to="rsg-text"
        className="flex max-w-full underline md:flex-col"
      >
        <div className="flex-row">
          <span>
            <Trans>Gaming is addictive.</Trans>
          </span>
        </div>

        <div className="flex-row">
          <span className="whitespace-pre md:hidden"> </span>
          <span>
            <Trans>Please play responsibly.</Trans>
          </span>
        </div>
      </Link>
      <div key="social-media-icons" className="flex gap-x-3 md:ml-auto">
        {createIconsGroup(socialMediaIcons)}
      </div>
    </div>
  )
}

const createIconsGroup = (icons: readonly FooterNavIcon[]) => {
  return icons.map(({ name, url, size, className }) => {
    return (
      <Link key={name} to={url}>
        <CustomIcon
          key={name}
          name={name as IconNames}
          size={size as IconSizes}
          wrapperClassName={className}
        />
      </Link>
    )
  })
}

export default Footer
