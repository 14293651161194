import {
  DayFlag,
  DayPicker,
  PropsBase,
  PropsSingle,
  SelectionState,
  UI,
} from 'react-day-picker'
import { Caption } from './components/caption'
import { CalendarContext } from './calendar.context'
import DayButton from './components/day-button'
import { useState } from 'react'

type Props = PropsBase &
  PropsSingle & {
    selected?: Date
    size?: 'sm' | 'md'
    official?: Date | Date[]
    inactiveYears?: number[]
    openTo?: 'year' | 'month'
    initialYear?: number
    yearsToRender?: number
  }

const Calendar = ({
  size = 'sm',
  inactiveYears,
  official = [],
  openTo,
  selected,
  initialYear = new Date().getFullYear() - 30,
  yearsToRender = 50,
  ...props
}: Props) => {
  const [isDefaultCaptionOpen, setIsDefaultCaptionOpen] = useState(
    openTo === 'year',
  )

  return (
    <CalendarContext.Provider
      value={{
        size,
        isDefaultCaptionOpen,
        setIsDefaultCaptionOpen,
        inactiveYears: new Set(inactiveYears),
        initialYear,
        yearsToRender,
      }}
    >
      <DayPicker
        showOutsideDays
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        selected={selected}
        defaultMonth={selected}
        className="border-0"
        components={{
          DayButton: (props) => <DayButton official={official} {...props} />,
          MonthCaption: Caption,
          Nav: () => <></>,
        }}
        classNames={{
          [UI.Weekdays]: 'flex font-medium text-content-muted gap-2',
          [UI.Weekday]:
            'flex align-middle justify-center font-normal text-sm text-content-muted p-1 w-7',
          [UI.Week]: 'flex w-full gap-2',
          [UI.MonthGrid]: 'flex flex-col gap-2',
          [UI.Weeks]: 'flex flex-col gap-2',
          [UI.Day]:
            'w-7 h-7 flex align-middle justify-center p-0 font-light rounded-md text-content-base',
          [UI.DayButton]:
            'text-center w-full h-full hover:bg-brand-secondary-shade hover:text-content-inverse rounded-md hover:font-medium text-base',
          [SelectionState.selected]:
            'bg-brand-secondary focus:bg-brand-secondary flex items-center justify-center !font-bold hover:bg-brand-secondary-shade',
          [DayFlag.outside]: 'text-content-disabled',
          [DayFlag.disabled]: 'text-content-disabled pointer-events-none',
          [DayFlag.hidden]: 'invisible',
          [UI.Month]:
            'flex flex-col p-3 gap-3 bg-form-input-surface rounded-md border border-solid border-form-input-border-active min-h-[326px]',
        }}
        {...props}
      />
    </CalendarContext.Provider>
  )
}

Calendar.displayName = 'Calendar'

export { Calendar }
