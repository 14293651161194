import { useLingui } from '@lingui/react/macro'
import { useQuery } from '@tanstack/react-query'
import {
  createPurchaseOptions,
  createSpecialOfferPurchaseOptions,
  PurchaseInitialiseResponse,
} from '@/apis/pam-store-api'
import { SelectedItem } from '@/features/store/store'

export type PurchaseData = PurchaseInitialiseResponse

const usePurchaseQueryOptions = (item: SelectedItem) => {
  const payload = {
    body: {
      itemId: item.itemId,
    },
  }

  if (item.type === 'special-offer-item') {
    return createSpecialOfferPurchaseOptions(payload)
  }

  return createPurchaseOptions(payload)
}

function usePurchaseQuery(item: SelectedItem) {
  const { t } = useLingui()
  const options = usePurchaseQueryOptions(item)

  const {
    data,
    isLoading,
    error: purchaseError,
  } = useQuery({
    ...options,
    enabled: !!item.itemId,
  })

  return {
    isLoading,
    data,
    error:
      purchaseError || (!data && !isLoading)
        ? t`An error has occurred`
        : undefined,
  }
}

export { usePurchaseQuery }
