import { useEffect, useState } from 'react'
import { calculateNextTickerAmount } from './calculate-next-ticker-amount'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'
import { useLingui } from '@lingui/react/macro'
import { msg } from '@lingui/core/macro'
import { MessageDescriptor } from '@lingui/core'

const JackpotTickerVariants = cva('rounded-md bg-gradient-to-b p-[1px]', {
  variants: {
    variant: {
      grand:
        'from-jackpot-ticker-border-grand-0 to-jackpot-ticker-border-grand-100',
      major:
        'from-jackpot-ticker-border-major-0 to-jackpot-ticker-border-major-100',
      minor:
        'from-jackpot-ticker-border-minor-0 to-jackpot-ticker-border-minor-100',
    },
  },
  defaultVariants: {
    variant: 'grand',
  },
})

const JackpotLegendVariants = cva(
  [
    'absolute',
    'bottom-0',
    'left-1/2',
    '-translate-x-1/2',
    'translate-y-1/2',
    'transform',
    'px-1',
    'text-[0.625rem]',
    'font-bold',
    'leading-3',
    "[--bg-color:theme('colors.jackpot-ticker-surface-base')]",
    '[background-image:linear-gradient(to_bottom,var(--bg-color)_60%,transparent_50%)]',
    'font-display',
  ],
  {
    variants: {
      variant: {
        grand: 'text-jackpot-ticker-content-grand',
        major: 'text-jackpot-ticker-content-major',
        minor: 'text-jackpot-ticker-content-minor',
      },
    },
    defaultVariants: {
      variant: 'grand',
    },
  },
)

const numberFormat = new Intl.NumberFormat()

export interface JackpotTickerProps
  extends VariantProps<typeof JackpotTickerVariants> {
  amount: number
  coinType: 'GC' | 'SC'
}

const coinLabelMapping: Record<
  JackpotTickerProps['coinType'],
  MessageDescriptor
> = {
  GC: msg`GC`,
  SC: msg`SC`,
}

const variantLabelMapping: Record<
  NonNullable<JackpotTickerProps['variant']>,
  MessageDescriptor
> = {
  grand: msg`GRAND`,
  major: msg`MAJOR`,
  minor: msg`MINOR`,
}

export const JackpotTicker = ({
  amount,
  variant = 'grand',
  coinType,
}: JackpotTickerProps) => {
  const { t } = useLingui()

  const [tickerAmount, setTickerAmount] = useState(amount)

  useEffect(() => {
    const timeout = setTimeout(() => {
      const nextAmount = calculateNextTickerAmount(tickerAmount, amount)
      setTickerAmount(nextAmount)
    }, 100)
    return () => clearTimeout(timeout)
  }, [amount, tickerAmount])

  return (
    <div className={cn(JackpotTickerVariants({ variant }))}>
      <div
        className={cn([
          'flex',
          'justify-center',
          'items-center',
          'rounded-md',
          'bg-jackpot-ticker-surface-base',
          'px-2',
          'pt-0.5',
          'pb-1',
          'relative',
        ])}
      >
        <div
          className={cn([
            'text-jackpot-ticker-content-base',
            'text-center',
            'text-xs',
            'font-bold',
            'leading-[14px]',
          ])}
          data-testid="jackpot-ticker"
        >
          {t(coinLabelMapping[coinType])} {numberFormat.format(tickerAmount)}
        </div>
        <span className={cn(JackpotLegendVariants({ variant }))}>
          {!!variant && t(variantLabelMapping[variant])}
        </span>
      </div>
    </div>
  )
}
