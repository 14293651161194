export const SpecialOfferItemsSetSkeleton = () => {
  return (
    <>
      <div className="aspect-video w-full animate-pulse rounded-md bg-skeleton" />
      {[...Array(3)].map((__, index) => (
        <div
          key={index}
          className="h-[68px] w-full animate-pulse rounded-md bg-skeleton"
        />
      ))}
    </>
  )
}
