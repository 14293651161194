import { useMemo, useState } from 'react'
import { I18n } from '@lingui/core'
import { msg } from '@lingui/core/macro'
import { Trans, useLingui } from '@lingui/react/macro'
import {
  Body,
  Close,
  Content,
  Dialog,
  Header,
  Overlay,
  Title,
} from '@vgw/multibrand-fe-dialog'
import { Tab, Tabs } from '@vgw/multibrand-fe-tabs'
import { Button } from '@vgw/multibrand-fe-button'
import { loadBrandConfig } from '@/config/config'
import { useStoreQuery } from '@/features/store/use-store-query'
import { useStoreLaunch } from '@/features/store/use-store-launch'
import { StoreTab } from '@/features/store/store-tab-enum'
import { SpecialOfferItemsSet } from '@/features/store/special-offer-items-set'
import { RegularStoreItemsSet } from '@/features/store/regular-store-items-set/regular-store-items-set'
import { Purchase } from '@/features/purchase/purchase'
import { PlayForFree } from '@/features/store/play-for-free/play-for-free'

const config = await loadBrandConfig()

const getTabs = (i18n: I18n): Tab[] => [
  {
    label: i18n._(msg`Play for FREE`),
    value: StoreTab.playForFree,
    content: '',
  },
  {
    label: i18n._(msg`Buy Gold Coins`),
    value: StoreTab.buyGoldCoins,
    content: '',
  },
]

type ItemType = 'special-offer-item' | 'regular-store-item'
export type SelectedItem = { type?: ItemType; itemId: string }

export const Store = () => {
  const { i18n } = useLingui()
  const { data: offersData, refetch, isLoading } = useStoreQuery()
  const { specialOfferSets = [], regularStoreItems = [] } = offersData || {}

  const tabs = useMemo(() => getTabs(i18n), [i18n])
  const { close, currentTab, changeTab } = useStoreLaunch()

  const [selectedItem, setSelectedItem] = useState<SelectedItem>({
    itemId: '',
  })

  const handleItemClick = ({ type, itemId }: SelectedItem) =>
    setSelectedItem({ type, itemId })

  return (
    <>
      <Dialog
        open={currentTab !== undefined && !selectedItem.itemId}
        onOpenChange={close}
      >
        <Overlay />
        <Content
          className="md:h-[90%] md:w-[480px] lg:w-[512px] xl:w-[512px]"
          isFullScreenOnMobile
        >
          <Close />
          <Header className="!gap-2 !px-2 !py-3">
            <Title className="text-center font-display text-2xl font-bold leading-9 text-content-base">
              <Trans>Get Coins</Trans>
            </Title>
            <Tabs
              className="md:mx-2 lg:mx-4 xl:mx-6"
              items={tabs}
              defaultValue={currentTab!}
              value={currentTab}
              onValueChange={(value: string) => changeTab(value as StoreTab)}
              hideContent
            />
          </Header>
          <Body className="!sm:px-4 !lg:px-6 !xl:px-6 xs:!px-2 mb-3">
            {currentTab === StoreTab.buyGoldCoins && (
              <div
                data-testid="buy-gold-coins-wrapper"
                className="mt-3 flex flex-col gap-6"
              >
                <div>
                  <h2 className="font-display text-lg font-bold leading-7 text-content-base">
                    <Trans>
                      <span className="capitalize">
                        {config.name.toLowerCase()}
                      </span>{' '}
                      is always FREE to play
                    </Trans>
                  </h2>
                  <div className="flex items-center">
                    <span className="font-body text-[15px] leading-4 text-content-base">
                      <Trans>No purchase necessary.</Trans>
                    </span>
                    <Button
                      onClick={() => changeTab(StoreTab.playForFree)}
                      variant="secondary"
                      size="sm"
                    >
                      <Trans>More info</Trans>
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  {specialOfferSets.map((item) => (
                    <SpecialOfferItemsSet
                      key={item.id}
                      isLoading={isLoading}
                      name={item.name}
                      validUntil={
                        item.validUntil ? new Date(item.validUntil) : undefined
                      }
                      claim={item.claim}
                      items={item.items}
                      onItemClick={(itemId) =>
                        handleItemClick({ type: 'special-offer-item', itemId })
                      }
                    />
                  ))}
                  <RegularStoreItemsSet
                    isLoading={isLoading}
                    items={regularStoreItems}
                    onItemClick={(itemId) =>
                      handleItemClick({ type: 'regular-store-item', itemId })
                    }
                  />
                </div>
              </div>
            )}
            {currentTab === StoreTab.playForFree && <PlayForFree />}
          </Body>
        </Content>
      </Dialog>

      <Purchase
        item={selectedItem}
        onExitPurchase={() => {
          setSelectedItem({ itemId: '' })
          refetch()
        }}
      />
    </>
  )
}
