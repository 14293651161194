import { PeriodicBonusItemProps } from '../periodic-bonus-item/periodic-bonus-item'

export const preparePeriodicBonuses = (): PeriodicBonusItemProps[] => [
  {
    index: 1,
    gc: 100000,
    sc: 1.5,
    loading: false,
    disabled: true,
    countdown: undefined,
    onCollectClick: () => {},
    variant: 'normalClaimed',
  },
  {
    index: 2,
    gc: 100000,
    sc: 1.5,
    loading: false,
    disabled: false,
    countdown: undefined,
    onCollectClick: () => {},
    variant: 'normalClaimable',
  },
  {
    index: 3,
    gc: 150000,
    sc: 1.5,
    loading: false,
    disabled: true,
    countdown: new Date(Date.now() + 24 * 60 * 60 * 1000), // 1 days from now
    onCollectClick: () => {},
    variant: 'normalNextDay',
  },
  {
    index: 4,
    gc: 200000,
    sc: 1.5,
    loading: false,
    disabled: true,
    countdown: undefined,
    onCollectClick: () => {},
    variant: 'normalPending',
  },
  {
    index: 5,
    gc: 250000,
    sc: 1.5,
    loading: false,
    disabled: true,
    countdown: undefined,
    onCollectClick: () => {},
    variant: 'normalPending',
  },
  {
    index: 6,
    gc: 300000,
    sc: 1.5,
    loading: false,
    disabled: true,
    countdown: undefined,
    onCollectClick: () => {},
    variant: 'normalPending',
  },
  {
    index: 7,
    gc: 350000,
    sc: 1.5,
    loading: false,
    disabled: true,
    countdown: undefined,
    onCollectClick: () => {},
    variant: 'normalPending',
  },
]
