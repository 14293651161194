export const PlayForFreeSkeleton = () => (
  <div className="flex flex-col gap-6">
    <div className="rounded-lg bg-surface-neutral p-3">
      <div className="mb-3 h-[26px] w-[130px] animate-pulse bg-skeleton" />
      <ul className="flex gap-3 overflow-x-hidden">
        {[...Array(6)].map((__, index) => (
          <li
            key={index}
            className="h-[185px] w-[116px] shrink-0 animate-pulse rounded-md bg-skeleton"
          />
        ))}
      </ul>
    </div>
    <div className="flex flex-col gap-3">
      {[...Array(6)].map((__, index) => (
        <div
          key={index}
          className="h-[90px] animate-pulse rounded-md bg-skeleton"
        />
      ))}
    </div>
  </div>
)
