import { Button } from '@vgw/multibrand-fe-button'
import { TextField } from '@vgw/multibrand-fe-text-field'
import { DatePicker } from '@vgw/multibrand-fe-date-picker'
import {
  Select,
  SelectTrigger,
  SelectItem,
  SelectContent,
  SelectItemText,
  SelectIcon,
} from '@vgw/multibrand-fe-select'
import { useSddForm } from './hooks/use-sdd-form'
import { COUNTRIES } from '@/lib/countries'
import { Controller } from 'react-hook-form'
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { getBrandConfig } from '@/config/config'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import { FormSection } from '@vgw/multibrand-fe-form-section'
import { Trans, useLingui } from '@lingui/react/macro'

export const SddForm = () => {
  const { name: brand } = getBrandConfig()
  const { register, formState, handleSubmit, watch, control } = useSddForm()
  const { t } = useLingui()

  const onSubmit = () => {}

  const selectedCountryCode = watch('country')

  return (
    <section className="m-auto max-w-[496px] flex-1 p-6">
      <IconButton icon="arrow-left" variant="ghost" />
      <h3 className="mb-4 mt-0.5 text-center text-xl font-bold text-content-base">
        <Trans>First Time Purchase</Trans>
      </h3>
      <p className="font-body text-content-muted">
        <Trans>
          {brand} is dedicated to the protection of our customers’ identities.
          Please provide a small amount of information about yourself in an
          effort to help keep your account safe
        </Trans>
      </p>
      <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-3">
          <FormSection>
            <TextField error={!!formState.errors.firstName}>
              <TextField.Label htmlFor="firstName">
                <Trans>First Name</Trans>
              </TextField.Label>
              <TextField.Input
                {...register('firstName')}
                autoComplete="given-name"
                data-testid="sdd-first-name-input"
              />
            </TextField>
            {!!formState.errors.firstName && (
              <FormSection.ErrorMessage className="text-sm">
                {formState.errors.firstName.message}
              </FormSection.ErrorMessage>
            )}
          </FormSection>
          <FormSection>
            <TextField error={!!formState.errors.lastName}>
              <TextField.Label htmlFor="lastName">
                <Trans>Last Name</Trans>
              </TextField.Label>
              <TextField.Input
                {...register('lastName')}
                autoComplete="family-name"
                data-testid="sdd-last-name-input"
              />
            </TextField>
            {!!formState.errors.lastName && (
              <FormSection.ErrorMessage className="text-sm">
                {formState.errors.lastName.message}
              </FormSection.ErrorMessage>
            )}
          </FormSection>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <FormSection>
                <DatePicker
                  htmlFor={name}
                  error={!!formState.errors.dateOfBirth}
                  label={t`Date of Birth`}
                  setSelectedDate={onChange}
                  selectedDate={value}
                  yearsToRender={121}
                  initialYear={new Date().getFullYear() - 120}
                />
                {!!formState.errors.dateOfBirth && (
                  <FormSection.ErrorMessage className="text-sm">
                    {formState.errors.dateOfBirth.message}
                  </FormSection.ErrorMessage>
                )}
              </FormSection>
            )}
          />
        </div>
        <section className="mt-6 space-y-3">
          <h4 className="font-medium">
            <Trans>Residental address</Trans>
          </h4>
          <Controller
            name="country"
            control={control}
            render={({ field: { name, onChange } }) => (
              <FormSection data-testid="sdd-country-select">
                <Select
                  error={!!formState.errors.country}
                  name={name}
                  autoComplete="country"
                  placeholder={t`Country of Residence`}
                  onValueChange={onChange}
                >
                  <SelectTrigger
                    startIcon={
                      <CustomIcon
                        name={
                          (selectedCountryCode &&
                            COUNTRIES[selectedCountryCode].icon) ||
                          'united-states'
                        }
                      />
                    }
                  >
                    <SelectIcon />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(COUNTRIES).map(
                      ([itemKey, { icon, name: itemName }]) => (
                        <SelectItem
                          value={itemKey}
                          key={itemKey}
                          className="flex gap-2"
                        >
                          <CustomIcon name={icon} />
                          <SelectItemText>{t(itemName)}</SelectItemText>
                        </SelectItem>
                      ),
                    )}
                  </SelectContent>
                </Select>
                {!!formState.errors.country && (
                  <FormSection.ErrorMessage className="text-sm">
                    {formState.errors.country.message}
                  </FormSection.ErrorMessage>
                )}
              </FormSection>
            )}
          />
          <FormSection>
            <TextField error={!!formState.errors.streetAddress}>
              <TextField.Label htmlFor="streetAddress">
                <Trans>Street Address</Trans>
              </TextField.Label>
              <TextField.Input
                {...register('streetAddress')}
                autoComplete="address-line1"
                data-testid="sdd-street-address-input"
              />
            </TextField>
            {!!formState.errors.streetAddress && (
              <FormSection.ErrorMessage className="text-sm">
                {formState.errors.streetAddress.message}
              </FormSection.ErrorMessage>
            )}
          </FormSection>
          <TextField>
            <TextField.Label htmlFor="buildingAddress">
              <Trans>Apt, Suite, Unit, Building (optional)</Trans>
            </TextField.Label>
            <TextField.Input
              {...register('buildingAddress')}
              autoComplete="address-line2"
            />
          </TextField>
          <Controller
            name="state"
            control={control}
            render={({ field: { name, onChange } }) => (
              <FormSection data-testid="sdd-state-select">
                <Select
                  name={name}
                  error={!!formState.errors.state}
                  disabled={!selectedCountryCode}
                  placeholder={t`State`}
                  autoComplete="address-level1"
                  onValueChange={(value) => {
                    onChange(value)
                  }}
                >
                  <SelectTrigger>
                    <SelectIcon />
                  </SelectTrigger>
                  <SelectContent>
                    {selectedCountryCode &&
                      COUNTRIES[selectedCountryCode]?.regions.map(
                        ({ name, code }) => (
                          <SelectItem value={code} key={code}>
                            <SelectItemText>{t(name)}</SelectItemText>
                          </SelectItem>
                        ),
                      )}
                  </SelectContent>
                </Select>
                {!!formState.errors.state && (
                  <FormSection.ErrorMessage className="text-sm">
                    {formState.errors.state.message}
                  </FormSection.ErrorMessage>
                )}
              </FormSection>
            )}
          />
          <FormSection>
            <TextField error={!!formState.errors.city}>
              <TextField.Label htmlFor="city">
                <Trans>City</Trans>
              </TextField.Label>
              <TextField.Input
                {...register('city')}
                autoComplete="address-level2"
                data-testid="sdd-city-input"
              />
            </TextField>
            {!!formState.errors.city && (
              <FormSection.ErrorMessage className="text-sm">
                {formState.errors.city.message}
              </FormSection.ErrorMessage>
            )}
          </FormSection>
          <FormSection>
            <TextField error={!!formState.errors.zip}>
              <TextField.Label htmlFor="zip">
                <Trans>Zip</Trans>
              </TextField.Label>
              <TextField.Input
                {...register('zip')}
                autoComplete="postal-code"
                data-testid="sdd-zip-input"
              />
            </TextField>
            {!!formState.errors.zip && (
              <FormSection.ErrorMessage className="text-sm">
                {formState.errors.zip.message}
              </FormSection.ErrorMessage>
            )}
          </FormSection>
        </section>
        <Button
          type="submit"
          className="mt-6 w-full"
          disabled={!formState.isValid}
        >
          <Trans>Submit</Trans>
        </Button>
      </form>
    </section>
  )
}
