import { getOidc } from '@/features/oidc/oidc'
import { redirect } from 'react-router-dom'
import { USER_LOGIN_PATH, USER_REGISTER_PATH } from '@/config/paths'

const redirectToAuthenticationPages = async (request: Request) => {
  const oidc = await getOidc()
  const url = new URL(request.url)

  if (oidc.isUserLoggedIn) {
    return redirect('/')
  } else {
    if (url.pathname === USER_LOGIN_PATH) {
      void oidc.login({ doesCurrentHrefRequiresAuth: false })
    }

    if (url.pathname === USER_REGISTER_PATH) {
      void oidc.login({
        doesCurrentHrefRequiresAuth: false,
        transformUrlBeforeRedirect: (url) => {
          const urlObj = new URL(url)

          urlObj.pathname = urlObj.pathname.replace(/\/auth$/, '/registrations')

          return urlObj.href
        },
      })
    }
  }

  return null
}

export { redirectToAuthenticationPages }
