import { cva, VariantProps } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'
import * as Tabs from '@radix-ui/react-tabs'
import { useEffect, useState } from 'react'
import { useLingui } from '@lingui/react/macro'
import { NavItem } from './nav-item'
import { useLocation } from 'react-router-dom'

const navbarVariants = cva(
  'z-1 bg-navigation-surface text-navigation-content-base data-[state=active]:text-content-base sticky px-4 pb-2 pt-3 backdrop-blur-sm lg:hidden',
  {
    variants: {
      variant: {
        // Full-width
        primary: 'bottom-0 inline-flex w-full space-x-px',
        // Full-width Highlighted
        secondary: 'bottom-0 inline-flex w-full space-x-px',
        // Rounded Partial
        tertiary:
          'bottom-2 inline-flex w-[95%] space-x-px rounded-full shadow-[0_2px_0px_0px_rgba(23,25,35,0.25)]',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
)

export type MobileNavbarItem = 'home' | 'store' | 'redeem' | 'search' | 'games'

const urlToTab: Record<string, MobileNavbarItem> = {
  '/': 'home',
  '/store': 'store',
  '/redeem': 'redeem',
  '/search': 'search',
  '/games': 'games',
}

export interface MobileNavbarProps extends VariantProps<typeof navbarVariants> {
  itemsWithIndicator?: MobileNavbarItem[]
  className?: string
  onChange?: (item: MobileNavbarItem) => void
}

const MobileNavbar = ({
  variant,
  className,
  itemsWithIndicator,
  onChange,
}: MobileNavbarProps) => {
  const { t } = useLingui()
  const location = useLocation()
  const [tab, setTab] = useState<MobileNavbarItem | null>('home')
  const navbarVariant = variant ?? ''

  useEffect(() => {
    const currentTab = urlToTab[location.pathname] || null

    setTab(currentTab)
  }, [location.pathname])

  const handleNavbarChange = (value: MobileNavbarItem) => {
    if (onChange) {
      onChange(value)
    }

    if (value === 'store') {
      setTab('home')
    } else {
      setTab(value)
    }
  }

  return (
    <Tabs.Root
      data-testid="mobile-navbar"
      className={cn(navbarVariants({ variant, className }))}
      value={tab ?? ''}
      onValueChange={(value) => handleNavbarChange(value as MobileNavbarItem)}
    >
      <Tabs.List aria-label={t`Mobile Nav Menu`} className="flex w-full">
        <NavItem
          value="home"
          label={t`Home`}
          icon="home-filled"
          navbarVariant={navbarVariant}
          hasNotification={itemsWithIndicator?.some((item) => item === 'home')}
        />
        <NavItem
          value="store"
          label={t`Store`}
          icon="shopping-bag"
          navbarVariant={navbarVariant}
          hasNotification={itemsWithIndicator?.some((item) => item === 'store')}
        />
        <NavItem
          value="redeem"
          label={t`Redeem`}
          icon="redeem"
          navbarVariant={navbarVariant}
          hasNotification={itemsWithIndicator?.some(
            (item) => item === 'redeem',
          )}
        />
        <NavItem
          value="search"
          label={t`Search`}
          icon="search"
          navbarVariant={navbarVariant}
          hasNotification={itemsWithIndicator?.some(
            (item) => item === 'search',
          )}
        />
        <NavItem
          value="games"
          label={t`Games`}
          icon="play-circle"
          navbarVariant={navbarVariant}
          hasNotification={itemsWithIndicator?.some((item) => item === 'games')}
        />
        <NavItem
          value=""
          label=""
          icon="play-circle"
          navbarVariant={navbarVariant}
          className="hidden"
        />
      </Tabs.List>
    </Tabs.Root>
  )
}

export default MobileNavbar
