import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useMemo } from 'react'
import { COUNTRIES } from '@/lib/countries'
import { useLingui } from '@lingui/react'
import { I18n } from '@lingui/core'
import { msg } from '@lingui/core/macro'

const textRegex = /^[a-zA-Z\s'-]+$/
const streetAddressRegex = /^[a-zA-Z0-9\s'-]+$/
const zipCodeRegex = /^[0-9-]+$/

const createSchema = (_: I18n['_']) => {
  return z.object({
    firstName: z.string().regex(textRegex, {
      message: _(
        msg`Your first name can only contain letters (a-z), spaces, hyphens and apostrophes.`,
      ),
    }),

    lastName: z.string().regex(textRegex, {
      message: _(
        msg`Your last name can only contain letters (a-z), spaces, hyphens and apostrophes.`,
      ),
    }),

    dateOfBirth: z.date(),

    streetAddress: z.string().regex(streetAddressRegex, {
      message: _(
        msg`Address can only contain letters (a-z), numbers, spaces, hyphens and apostrophes.`,
      ),
    }),

    country: z.enum(Object.keys(COUNTRIES) as [keyof typeof COUNTRIES], {
      message: _(msg`Country of Residence is required`),
    }),

    buildingAddress: z.string(),

    state: z
      .string({ message: _(msg`State is required`) })
      .min(1, { message: _(msg`State is required`) }),

    city: z.string().regex(textRegex, {
      message: _(
        msg`City can only contain letters (a-z), spaces, hyphens and apostrophes.`,
      ),
    }),

    zip: z.string().regex(zipCodeRegex, {
      message: _(msg`Zip code can only contain numbers and hyphens.`),
    }),
  })
}

export interface SddFormFieldValues
  extends z.infer<ReturnType<typeof createSchema>> {}

export const useSddForm = () => {
  const { _ } = useLingui()

  return useForm<SddFormFieldValues>({
    mode: 'all',
    resolver: zodResolver(useMemo(() => createSchema(_), [_])),
  })
}
