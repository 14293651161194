import { FC, HTMLAttributes, ReactNode } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'
import { CustomIcon, IconNames } from '@vgw/multibrand-fe-icon'

const calloutVariants = cva(
  ['flex', 'border', 'rounded-radius-base', 'p-3', 'gap-2', 'min-w-[300px]'],
  {
    variants: {
      variant: {
        neutral: ['border-border-muted', 'bg-status-default'],
        error: ['border-status-error', 'bg-status-error-muted'],
        success: ['border-status-success', 'bg-status-success-muted'],
        warning: ['border-status-warning', 'bg-status-warning-muted'],
        info: ['border-status-info', 'bg-status-info-muted'],
      },
    },
    defaultVariants: {
      variant: 'neutral',
    },
  },
)

export interface CalloutProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof calloutVariants> {
  className?: string
  variant?: 'neutral' | 'error' | 'success' | 'warning' | 'info'
  title?: string
  description: ReactNode
}

const Callout: FC<CalloutProps> = ({
  className,
  variant,
  title,
  description,
  ...props
}: CalloutProps) => {
  let iconName: IconNames = 'info-circled'

  if (variant === 'error' || variant === 'warning') {
    iconName = 'exclamation-triangle'
  } else if (variant === 'success') {
    iconName = 'check-circled'
  }

  return (
    <div className={cn(calloutVariants({ variant, className }))} {...props}>
      <CustomIcon
        wrapperClassName={cn({ 'mt-[2px]': title })}
        name={iconName}
        size="sm"
      />
      <div className="flex flex-col gap-1">
        {title && <p className="text-lg font-bold">{title}</p>}
        <p className={cn('text-base leading-6', { 'leading-none': !title })}>
          {description}
        </p>
      </div>
    </div>
  )
}

Callout.displayName = 'Callout'

export { Callout }
