import {
  Carousel,
  useDotButton,
  usePrevNextButtons,
  WheelGesturesPlugin,
  AutoPlay,
} from '@vgw/multibrand-fe-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { cva, VariantProps } from 'class-variance-authority'
import { type ClassValue } from 'clsx'
import { cn } from '@vgw/tailwind-merger'
import { NavigationArrows } from './navigation-arrows'

const mainElementVariants = cva(['w-full'], {
  variants: {
    variant: {
      basic: '',
      leadingCard: ['duration-600 transition-all ease-in-out'],
    },
  },
  defaultVariants: {
    variant: 'basic',
  },
})

export type CarouselVariant = 'basic' | 'leadingCard'

interface CarouselHeroBannerBaseProps
  extends VariantProps<typeof mainElementVariants> {
  className?: string
  cards: CarouselHeroBannerBaseCard[]
  /**
   * Set the autoplay speed. Set to false if you want to disable autoplay completely.
   */
  autoPlayDelay?: number | false
  nonInteractive?: boolean
}

export interface CarouselHeroBannerBaseCard {
  MainElement: (props: { classNames: ClassValue[] }) => JSX.Element
}

export const CarouselHeroBannerBase = ({
  className,
  cards,
  autoPlayDelay = 7000,
  variant,
  nonInteractive,
}: CarouselHeroBannerBaseProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
    },
    [
      WheelGesturesPlugin(),
      ...(autoPlayDelay !== false
        ? [
            AutoPlay({
              rootNode: (emblaRoot: HTMLElement) =>
                emblaRoot.closest('.js-carousel-container'),
              playOnInit: true,
              delay: autoPlayDelay,
              stopOnMouseEnter: true,
              stopOnInteraction: false,
            }),
          ]
        : []),
    ],
  )

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi)
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const isSingleCard = cards.length === 1

  return (
    <Carousel
      data-testid="carousel-hero-banner"
      className={cn(className, 'js-carousel-container flex flex-col gap-2', {
        'pointer-events-none': nonInteractive,
      })}
    >
      <NavigationArrows
        leftNavigationDisabled={prevBtnDisabled || nonInteractive!}
        onLeftNavigation={onPrevButtonClick}
        rightNavigationDisabled={nextBtnDisabled || nonInteractive!}
        onRightNavigation={onNextButtonClick}
        isSingleCard={isSingleCard}
      />

      <Carousel.Container ref={emblaRef} className="flex flex-col gap-3">
        <Carousel.CardContainer>
          {cards.map(({ MainElement }, index) => (
            <Carousel.Card
              key={index}
              index={index}
              className={cn(
                'flex w-[90%] items-center px-1 sm:px-2 lg:px-3 xl:w-[80%] 2xl:w-[70%]',
                {
                  'w-full': isSingleCard,
                },
              )}
              data-testid={`card-${index}`}
            >
              <MainElement
                classNames={[
                  'rounded-radius-base aspect-video h-full w-full object-cover',
                  mainElementVariants({ variant }),
                  {
                    'h-full': variant === 'basic' || selectedIndex === index,
                    'h-[90%]':
                      variant === 'leadingCard' && selectedIndex !== index,
                  },
                ]}
              />
            </Carousel.Card>
          ))}
        </Carousel.CardContainer>
        <Carousel.IndicatorsContainer data-testid="indicators-container">
          {scrollSnaps.map((_, index) => (
            <Carousel.Indicator
              key={`indicator-${index}`}
              data-testid={`indicator-${index}`}
              isActive={selectedIndex === index && !nonInteractive}
              onClick={() => onDotButtonClick(index)}
            />
          ))}
        </Carousel.IndicatorsContainer>
      </Carousel.Container>
    </Carousel>
  )
}
