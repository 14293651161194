import { useMemo } from 'react'
import { useLingui } from '@lingui/react/macro'
import {
  OverlayedHeroBannerBase,
  OverlayedHeroBannerBaseCard,
} from './overlayed-hero-banner-base'

interface OverlayedHeroBannerCard {
  src: string
  url?: string
  title: string
  sizes?: string
  srcSet?: string
  thumbnailSrc: string
  onCardClick?: () => void
}

interface OverlayedHeroBannerProps {
  cards: OverlayedHeroBannerCard[]
  onCardEntersViewport?: (cardIndex: number) => void
  /**
   * Set the autoplay speed. Set to false if you want to disable autoplay completely.
   */
  autoPlayDelay?: number | false
}

export const OverlayedHeroBanner = ({
  cards,
  onCardEntersViewport,
  autoPlayDelay = 7000,
}: OverlayedHeroBannerProps) => {
  const { t } = useLingui()

  const slides = useMemo(
    () =>
      cards.map<OverlayedHeroBannerBaseCard>((card) => {
        return {
          MainElement: ({ className }) => {
            const img = (
              <img
                alt={t`Navigate to ${card.url}`}
                src={card.src}
                sizes={card.sizes}
                srcSet={card.srcSet}
                className={className}
              />
            )

            return card.url ? (
              <a
                href={card.url}
                onClick={() => card.onCardClick?.()}
                className="w-full"
              >
                {img}
              </a>
            ) : (
              img
            )
          },
          ThumbnailElement: ({ className }) => (
            <img
              alt={t`Select ${card.title}`}
              src={card.thumbnailSrc}
              className={className}
            />
          ),
        }
      }),
    [cards, t],
  )

  return (
    <OverlayedHeroBannerBase
      cards={slides}
      onCardEntersViewport={onCardEntersViewport}
      autoPlayDelay={autoPlayDelay}
    />
  )
}
