import { useQuery } from '@tanstack/react-query'
import { offersOptions } from '@/apis/pam-store-api'

function useStoreQuery() {
  const options = offersOptions()

  const { data, refetch, isLoading } = useQuery(options)

  return {
    data,
    refetch,
    isLoading,
  }
}

export { useStoreQuery }
