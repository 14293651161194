import { Trans } from '@lingui/react/macro'
import { Button } from '@vgw/multibrand-fe-button'
import { cva, type VariantProps } from 'class-variance-authority'
import chumbaPouchImageUrl from './assets/chumba_pouch.png'
import chumbaPouchx2ImageUrl from './assets/chumba_pouch_x2.png'
import {
  GcFormatter,
  ScFormatter,
} from '@/features/price-formatter-utils/store-price-formatter'
import { CustomIcon, IconNames } from '@vgw/multibrand-fe-icon'
import { useCountdownDuration } from '@/features/datetime-utils/use-countdown-duration'
import { useDurationFormat } from '@/features/datetime-utils/use-duration-format'
import { cn } from '@vgw/tailwind-merger'

const periodicBonusVariants = cva(
  'flex w-[116px] flex-col rounded-md border-2 p-2 text-center',
  {
    variants: {
      variant: {
        normalClaimable:
          'border-yellow-300 bg-yellow-50 shadow-periodic-bonus-item',
        normalNextDay:
          'border-yellow-300 bg-yellow-50 shadow-periodic-bonus-item',
        normalPending:
          'border-surface-base bg-surface-base text-content-muted shadow-periodic-bonus-item',
        normalClaimed:
          'border-surface-base bg-surface-base text-content-disabled shadow-periodic-bonus-item grayscale',
        // TODO: change these colors to get from tailwind, colors in designs are broken
        specialClaimed:
          'border-muted bg-gradient-to-b from-[#fffabd] to-[#ffe668] text-content-disabled grayscale',
        // TODO: change these colors to get from tailwind, colors in designs are broken
        specialClaimable:
          'relative border-transparent bg-gradient-to-b from-[#fffabd] to-[#ffe668] bg-clip-padding before:absolute before:-inset-[2px] before:z-[-1] before:block before:rounded-md before:bg-gradient-to-b before:from-[#ffec00] before:to-[#e5a200] after:absolute after:-inset-0 after:z-[-1] after:rounded-md after:bg-white',
        // TODO: change these colors to get from tailwind, colors in designs are broken
        specialPending:
          'relative border-transparent bg-gradient-to-b from-[#fffabd] to-[#ffe668] bg-clip-padding before:absolute before:-inset-[2px] before:z-[-1] before:block before:rounded-md before:bg-gradient-to-b before:from-[#ffec00] before:to-[#e5a200] after:absolute after:-inset-0 after:z-[-1] after:rounded-md after:bg-white',
      },
    },
    defaultVariants: {
      variant: 'normalClaimable',
    },
  },
)

type PeriodicBonusVariants = VariantProps<typeof periodicBonusVariants>
type Variant = PeriodicBonusVariants['variant']

const getIconForCollectButton = (variant: Variant): IconNames => {
  if (variant === 'normalClaimed' || variant === 'specialClaimed') {
    return 'check'
  }

  return 'lock-closed'
}

export type PeriodicBonusItemProps = {
  index: number
  gc: number
  sc?: number
  loading?: boolean
  disabled?: boolean
  countdown?: Date
  onCollectClick: () => void
} & PeriodicBonusVariants

export const PeriodicBonusItem = ({
  gc,
  sc,
  index,
  loading,
  variant,
  disabled,
  countdown,
  onCollectClick,
}: PeriodicBonusItemProps) => {
  const formatDuration = useDurationFormat()
  const duration = useCountdownDuration(countdown)

  return (
    <div
      className={cn(periodicBonusVariants({ variant }), 'shrink-0')}
      data-testid="periodic-bonus-item"
    >
      <strong className="font-display">
        <Trans>Day</Trans> {index}
      </strong>
      <img
        className="mx-auto my-0.5"
        width={42}
        height={45}
        src={chumbaPouchImageUrl}
        srcSet={`${chumbaPouchImageUrl} 42w, ${chumbaPouchx2ImageUrl} 84w`}
        alt=""
      />
      <div className="flex-1">
        <p data-testid="periodic-bonus-item-gold-coin">
          <strong>
            <Trans>GC</Trans>
          </strong>{' '}
          {GcFormatter.format(gc)}
        </p>
        {typeof sc === 'number' && (
          <p data-testid="periodic-bonus-item-sweeps-coin">
            <strong>
              <Trans>+ SC</Trans>
            </strong>{' '}
            {ScFormatter.format(sc)}
          </p>
        )}
      </div>
      <Button
        disabled={disabled}
        className="mt-1 h-10 w-full px-0"
        loading={loading}
        onClick={onCollectClick}
        data-testid="periodic-bonus-item-button"
      >
        {disabled ? (
          <>
            <CustomIcon
              size="sm"
              name={getIconForCollectButton(variant)}
              fill="fill-content-disabled"
            />
            {!!duration && (
              <span className="ml-0.5 w-[70px]">
                {formatDuration(duration)}
              </span>
            )}
          </>
        ) : (
          <Trans>Collect</Trans>
        )}
      </Button>
    </div>
  )
}
