import { SpecialOfferItem } from '@/features/store/special-offer-items-set/special-offer-item/special-offer-item'
import { isPast } from 'date-fns'
import { Callout } from '@vgw/multibrand-fe-callout'
import { useLingui } from '@lingui/react/macro'

export interface SpecialOffer {
  id: string
  originalPrice: number
  discountPrice: number
  currency: string
  contents: { type?: 'GC' | 'SC' | 'FSC' | 'RSC'; quantity: number }[]
}

interface SpecialOfferItemsProps {
  items: SpecialOffer[]
  onItemClick: (id: string) => void
  validUntil?: Date
  claim: {
    limit?: number
    count: number
  }
}

const SpecialOfferItems = ({
  items,
  onItemClick,
  validUntil,
  claim,
}: SpecialOfferItemsProps) => {
  const { t } = useLingui()

  if (validUntil && isPast(validUntil)) {
    return (
      <Callout
        className="self-stretch"
        variant="warning"
        description={t`This offer has expired`}
      />
    )
  }

  if (claim.limit && claim.count >= claim.limit) {
    return (
      <Callout
        className="self-stretch"
        variant="info"
        description={t`You have claimed this offer ${claim.count} times`}
      />
    )
  }

  return items.map((item) => {
    return (
      <SpecialOfferItem
        key={item.id}
        id={item.id}
        originalPrice={item.originalPrice}
        discountPrice={item.discountPrice}
        currency={item.currency}
        onItemClick={onItemClick}
        gc={item.contents.find((item) => item.type === 'GC')?.quantity ?? 0}
        sc={item.contents.find((item) => item.type === 'FSC')?.quantity ?? 0}
      />
    )
  })
}

export { SpecialOfferItems }
