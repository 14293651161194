import { useOidc } from '@/features/oidc/oidc'
import { TrackingProvider } from '@vgw/multibrand-fe-tracking'
import { ReactNode } from 'react'

interface TrackingProps {
  apiKey: string
  children: ReactNode
}

function Tracking({ apiKey, children }: TrackingProps) {
  const { oidcTokens, isUserLoggedIn } = useOidc()

  return (
    <TrackingProvider
      apiKey={apiKey}
      isUserLoggedIn={isUserLoggedIn}
      pamAccountId={oidcTokens?.decodedIdToken?.['pam-aid'] as string}
    >
      {children}
    </TrackingProvider>
  )
}

export { Tracking }
