import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const SpecialOfferItemsSetWrapper = ({ children }: Props) => {
  return (
    <section
      className="bg-store flex w-full flex-col items-start gap-3 rounded-lg border border-solid border-store-promotional-area-border bg-store-promotional-area-surface p-3"
      data-testid="special-offer-items-set-wrapper"
    >
      {children}
    </section>
  )
}
