import { UserHeader } from '../user-header/user-header'
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { cn } from '@vgw/tailwind-merger'
import { Link } from 'react-router-dom'
import { HeaderProps } from '../types'

export const InGameHeader = ({
  user: { userName, logout, userEmail },
  className,
  coinTypeInfo,
}: HeaderProps) => {
  return (
    <header
      className={cn(
        'flex w-full flex-col items-center bg-navigation-surface shadow-header md:bg-navigation-surface',
        className,
      )}
      data-testid="page-header"
    >
      <div className="flex w-full max-w-screen-2xl items-center justify-between gap-2 px-2 py-2.5 sm:px-4 md:px-6">
        <IconButton
          asChild
          variant="outline"
          icon="arrow-left"
          size="sm"
          data-testid="header-back-button"
        >
          <Link to="/" />
        </IconButton>
        <UserHeader
          withCompactStoreIcon
          logout={logout}
          username={userName}
          userEmail={userEmail}
          coinTypeInfo={coinTypeInfo}
        />
      </div>
    </header>
  )
}
