import { CustomIcon, IconNames } from '@vgw/multibrand-fe-icon'
import { Accordion, AccordionItemProps } from '@vgw/multibrand-fe-accordion'
import { Link } from 'react-router-dom'
import { SiteNavItem } from './site-nav-item'
import { NavSeparator } from './nav-separator'
import { useLingui, Trans } from '@lingui/react/macro'
import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/core/macro'
import { getBrandConfig } from '@/config/config'
import { useMemo } from 'react'

interface UserAccordionItem extends Pick<AccordionItemProps, 'value'> {
  label: MessageDescriptor
  content: {
    label: MessageDescriptor
    icon?: IconNames
  }[]
}

interface UserSidebarNavigationProps {
  logout: () => Promise<void>
}

const accordions: UserAccordionItem[] = [
  {
    label: msg`My Account`,
    value: 'my-account',
    content: [
      { label: msg`Account Verification` },
      { label: msg`Bank Details` },
      { label: msg`Account History` },
      { label: msg`Activity Log` },
    ],
  },
  {
    label: msg`Your Play`,
    value: 'your-play',
    content: [
      { label: msg`Purchase Limit` },
      { label: msg`Self Exclusion` },
      { label: msg`Activity Reminder` },
      { label: msg`Take Break` },
    ],
  },
  {
    label: msg`Gameplay`,
    value: 'gameplay',
    content: [
      //
      { label: msg`Gameplay History` },
    ],
  },
  {
    label: msg`Settings`,
    value: 'settings',
    content: [
      //
      { label: msg`Settings`, icon: 'speaker-off' },
    ],
  },
]

const externalLinks = [
  {
    label: msg`Terms & Policies`,
    to: '',
  },
]

export const UserSidebarNavigation = ({
  logout,
}: UserSidebarNavigationProps) => {
  const { t } = useLingui()
  const { helpCenterUrl } = getBrandConfig()
  const links = useMemo(() => {
    return [...externalLinks, { label: msg`Help Center`, to: helpCenterUrl }]
  }, [helpCenterUrl])

  return (
    <nav className="relative flex flex-col justify-between gap-3 p-3">
      {/* TODO: replace hasNotifications with real value when BE will be ready */}
      <SiteNavItem
        hasNotifications={true}
        label={t`Notifications`}
        to="/"
        iconName="bell"
      />
      <NavSeparator />
      <Accordion
        type="multiple"
        className="space-y-3 py-3 font-medium"
        items={accordions.map((accordion) => ({
          label: t(accordion.label),
          value: accordion.value,
          content: (
            <ul className="space-y-4 border-l border-border-muted pl-3">
              {accordion.content.map((item) => (
                <li
                  key={item.label.id}
                  className="flex items-center justify-between"
                  data-testid="user-accordion-item"
                >
                  <span className="break-words text-sm font-normal leading-5 text-content-base">
                    {t(item.label)}
                  </span>
                  {item.icon && <CustomIcon name={item.icon} size="sm" />}
                </li>
              ))}
            </ul>
          ),
        }))}
      />
      <NavSeparator />
      <ul className="flex flex-col gap-6 py-3">
        {links.map(({ label, to }) => (
          <li key={label.id}>
            <Link
              to={to}
              target="_blank"
              className="flex items-center justify-between px-3"
            >
              <span className="break-words text-sm font-normal leading-5 text-content-base">
                {t(label)}
              </span>
              <CustomIcon name="external-link" size="sm" />
            </Link>
          </li>
        ))}
      </ul>
      <NavSeparator />
      <button
        className="flex w-full items-center justify-between break-words p-3 text-sm font-normal leading-5 text-content-base"
        onClick={logout}
      >
        <Trans>Log Out</Trans>
        <CustomIcon name="exit" size="sm" />
      </button>
    </nav>
  )
}
