import { useQuery } from '@tanstack/react-query'
import {
  getStateOptions,
  PlayerTokenStateResponse,
  tokenType,
} from '@/apis/pam-player-tokens-api'
import { CoinsState } from './types'

function useCoinsStateQuery() {
  const { isLoading, data, error } = useQuery({
    ...getStateOptions(),
    refetchInterval: 5000,
  })

  return {
    isLoading,
    data: data && getCoinAmount(data),
    error,
  }
}

function getCoinAmount(data: PlayerTokenStateResponse): CoinsState {
  return {
    goldCoinsAmount: getTokenAmount(data.tokens, 'GC') || 0,
    sweepsCoinsAmount: getSweepsAmount(
      getTokenAmount(data.tokens, 'FSC'),
      getTokenAmount(data.tokens, 'RSC'),
    ),
  }
}

function getTokenAmount(
  tokens: PlayerTokenStateResponse['tokens'],
  type: tokenType,
) {
  return tokens?.find(({ tokenType }) => tokenType === type)?.amount
}

function getSweepsAmount(fscAmount?: number, rscAmount?: number): number {
  return (fscAmount || 0) + (rscAmount || 0)
}

export { useCoinsStateQuery }
