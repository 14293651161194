import { useEffect } from 'react'
import { Trans } from '@lingui/react/macro'
import { Dialog, Overlay, Content } from '@vgw/multibrand-fe-dialog'
import { SelectedItem } from '@/features/store/store'
import { usePurchaseQuery } from '@/features/purchase/use-purchase-query'
import { buildIFrameUrlAttribute } from './build-iframe-url-attribute'

type PurchaseProps = {
  item: SelectedItem
  onExitPurchase: () => void
}

export const Purchase = ({ item, onExitPurchase }: PurchaseProps) => {
  const purchase = usePurchaseQuery(item)

  const getContent = () => {
    const { isLoading, data, error } = purchase

    if (isLoading)
      return (
        <span>
          <Trans>Loading</Trans>
        </span>
      )

    if (error)
      return (
        <p>
          <Trans>An error has occurred</Trans>
        </p>
      )

    const url = data?.paymentRedirectUrl || ''

    return (
      <iframe
        data-testid="purchase-iframe"
        title="Purchase"
        className="h-full w-full"
        allow="payment"
        src={url && buildIFrameUrlAttribute(url)}
      ></iframe>
    )
  }

  useEffect(() => {
    const handleExitPurchase = (event: MessageEvent) => {
      if (event.data?.type === 'exit-selected') {
        onExitPurchase()
      }
    }

    window.addEventListener('message', handleExitPurchase)
    return () => {
      window.removeEventListener('message', handleExitPurchase)
    }
  }, [onExitPurchase])

  return (
    <Dialog open={!!item.itemId}>
      <Overlay className="z-20" />
      <Content
        className="h-full overflow-hidden border-none md:w-[480px] lg:w-[512px] xl:w-[512px]"
        isFullScreenOnMobile
      >
        {getContent()}
      </Content>
    </Dialog>
  )
}
