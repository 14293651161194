import { Trans } from '@lingui/react/macro'
import { RegularStoreItem } from './regular-store-item/regular-store-item'
import { goldCoinImageMap } from '../assets/gold-coins'
import { RegularStoreItemsSetSkeleton } from './regular-store-items-set-skeleton'

type StoreItem = {
  id: string
  price: number
  currency: string
  contents: { type: 'GC' | 'SC' | 'FSC' | 'RSC'; quantity: number }[]
}

type RegularStoreItemsSetProps = {
  items: StoreItem[]
  onItemClick: (id: string) => void
  isLoading: boolean
}

export const RegularStoreItemsSet = ({
  items,
  onItemClick,
  isLoading,
}: RegularStoreItemsSetProps) => {
  return (
    <section className="w-full" data-testid="regular-offer-items-set-wrapper">
      <h3 className="flex gap-2 font-display text-lg font-bold leading-7">
        <Trans>Buy Gold Coins</Trans>
        <img
          alt=""
          src={goldCoinImageMap.multistack_gconly_md.small}
          width={30}
          height={30}
        />
      </h3>
      <div className="mt-3 flex flex-col gap-3">
        {!isLoading &&
          items.map((item) => {
            return (
              <RegularStoreItem
                id={item.id}
                key={item.id}
                price={item.price}
                contents={item.contents}
                currency={item.currency}
                onItemClick={onItemClick}
              />
            )
          })}
        {isLoading && <RegularStoreItemsSetSkeleton />}
      </div>
    </section>
  )
}
