import { useQuery } from '@tanstack/react-query'
import { getBrandConfig } from '@/config/config'
import { GalleryResponse, getGalleryViewOptions } from '@/apis/gap-gallery-view'

import { useSelectedCoinType } from '@/use-selected-coin-type'

export type GameGalleryResponse = GalleryResponse

export type GameCategory = GameGalleryResponse['categories'][number]
export type Game = GameCategory['games'][number]

function useGalleryQuery() {
  const config = getBrandConfig()
  const selectedCoinType = useSelectedCoinType()

  const { data, isLoading, error } = useQuery({
    ...getGalleryViewOptions({
      query: {
        coinType: selectedCoinType,
      },
      path: {
        brandCode: config.code,
      },
    }),
  })

  return {
    isLoading: isLoading,
    data,
    error,
  }
}

export { useGalleryQuery }
